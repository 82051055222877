@tailwind base;
@tailwind components;
@tailwind utilities;

.delivery {
  height: 100% !important;
}

/* Google Map */
.gm-bundled-control-on-bottom {
  position: static !important;
  margin: 0 !important;
}
.gmnoprint {
  left: 1rem !important;
  top: 5rem !important;
  padding: 0 !important;
}
.gmnoprint > div {
  width: 35px !important;
  display: flex;
  flex-direction: column;
  border-radius: 999999px !important;
  background: white !important;
}
.gmnoprint > div button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 35px !important;
  background: none !important;
}

/* الزر الأول (زر +) */
.gmnoprint > div button:first-child {
  border-top-left-radius: 999999px !important;
  border-top-right-radius: 999999px !important;
}

.gmnoprint > div button:first-child:hover {
  background: #ebebeb !important;
  border-top-left-radius: 999999px !important;
  border-top-right-radius: 999999px !important;
}

/* الزر الأخير (زر -) */
.gmnoprint > div button:last-child {
  border-bottom-left-radius: 999999px !important;
  border-bottom-right-radius: 999999px !important;
}

.gmnoprint > div button:last-child:hover {
  background: #ebebeb !important;
  border-bottom-left-radius: 999999px !important;
  border-bottom-right-radius: 999999px !important;
}

.gmnoprint > div img {
  width: 10px !important;
  height: 10px !important;
}

/* Input Animation */
input[type='text']:focus ~ .input-text {
  @apply text-main-900 -translate-y-5 translate-x-2 scale-75;
}

/* leaflet */
@import url('~leaflet/dist/leaflet.css');
.leaflet-container {
  width: 100%;
  height: 300px;
}
.leaflet-control-geocoder.leaflet-bar.leaflet-control.leaflet-control-geocoder-expanded.leaflet-control-geocoder-options-open {
  border-radius: 5px !important;
}
.leaflet-control-geocoder.leaflet-bar,
.leaflet-control-geocoder-icon {
  border-radius: 999px !important;
}
.leaflet-control-geocoder-icon {
  opacity: 0.6;
}
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  border-radius: 10px 10px 0 0 !important;
}
.leaflet-top.leaflet-right {
  left: 0 !important;
  right: auto;
}
.leaflet-top.leaflet-left {
  top: 25%;
  transform: translateY(-75%);
}
.leaflet-control-zoom.leaflet-bar.leaflet-control,
.leaflet-control-geocoder.leaflet-bar.leaflet-control {
  border: none !important;
}
.leaflet-control-geocoder.leaflet-bar.leaflet-control {
  margin-right: 0;
  margin-left: 10px;
}
.leaflet-control-zoom-out {
  border-radius: 0 0 10px 10px !important;
}
.leaflet-control-zoom-in span,
.leaflet-control-zoom-out span {
  opacity: 0.6;
}
.target {
  position: absolute;
  top: 35%;
  transform: translateY(-65%);
  left: 0;
  margin-left: 10px;
  margin-top: 10px;
  z-index: 400;
  padding: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

* {
  touch-action: pan-y pan-x !important;
  -webkit-user-select: none;
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard property */
}
.element {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard property */
}
@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer base {
  body {
    @apply dark:bg-gray-900;
  }
}

@layer components {
  .swiper {
    @apply dark:bg-gray-700;
  }
  .my-custom-pagination {
    @apply py-4 px-0 gap-2 pr-2 overflow-x-auto dark:bg-gray-700 whitespace-nowrap w-full sticky z-[40] inset-0 bg-white;
  }
  .delivery-pagination {
    @apply py-4 flex justify-center px-0 gap-2 pr-2 overflow-x-auto dark:bg-gray-700 whitespace-nowrap w-full sticky z-[50] inset-0 bg-white;
  }
  .my-custom-pagination > span,
  .delivery-pagination > span {
    @apply bg-gray-100 dark:bg-gray-900 dark:text-white;
  }
  .swiper-pagination-bullet-active {
    @apply !text-white !bg-[#71a42a];
  }
}
.my-custom-pagination {
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}
.swiper-pagination-bullet {
  align-items: center !important;
  display: inline-flex !important;
  font-weight: bold !important;
  height: 40px !important;
  justify-content: center !important;
  width: 80px !important;
  border-radius: 10000px !important;
  flex-direction: row-reverse;
  gap: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  opacity: 1 !important;
  font-size: 0.82rem;
  line-height: 1rem;
  font-weight: 600;
  margin: 0 0 0 0.5rem !important;
  padding: 0.7rem !important;
  height: 2.5rem !important;
  width: auto !important;
  white-space: nowrap;
}

.animateItems {
  animation: up 0.4s linear;
  transform-origin: bottom;
  transition: transform 0.3s ease-out;
}

@keyframes up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fastAnimate {
  animation: upFast 0.5s ease-out;
}
.fastAnimate {
  animation: upFast 0.5s ease-out;
}

@keyframes upFast {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0);
  }
}

.eax {
  margin-left: 17px;
  margin-top: 17px;
  cursor: pointer;
  height: 24px !important;
  width: 24px !important;
  transition: all 0.3s ease;
}
.eax2 {
  cursor: pointer;
  height: 24px !important;
  width: 24px !important;
  transition: all 0.3s ease;
}

body,
input,
textarea,
button,
select,
div,
.eax,
.class,
p,
a {
  -webkit-tap-highlight-color: transparent;
}

.rounded-md {
  border-radius: 20px;
}

.rounded-sid {
  border-radius: 20px 0px 0px 0px !important;
}
.bg-primaryGreen-300 {
  border-radius: 20px 0px 0px 0px !important;
}

.bg-black {
  backdrop-filter: blur(3px) saturate(80%);
}

.pl-2 {
  padding-left: 2rem;
}

.-right-2 {
  right: -0.6rem;
  top: -0.2rem;
}
.icon-flip {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

.foa9l {
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  padding: 5px;
}

.dark body {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  display: none;
}
html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

/* استايل السوشل ميديا في القائمه الجانبية */
.flex2 {
  display: flex;
  overflow-x: auto; /* يتيح التمرير الأفقي */
  white-space: nowrap; /* يمنع الأسطر المتعددة */
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem; /* المسافة بين النص والسهم */
  padding: 0.5rem;
  background-color: black;
  color: white;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  transition: opacity 0.3s;
}

a:hover + .tooltip,
a:focus + .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: -0.5rem; /* تعديل المسافة حسب الحاجة */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid black;
}
/*  نهاية الاستايل السوشل ميديا في القائمه الجانبية */

/* Custom background for order cards */
.order-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* استايلات البنر */
.banner-container {
  width: 100%;
  height: 155px; /* تحديد ارتفاع البنر */
  overflow: hidden; /* للتأكد من عدم تجاوز محتوى البنر للحدود */
  position: relative; /* لإضافة محتوى داخل الحاوية */
  touch-action: pan-y; /* لتعطيل التمرير الأفقي */
  outline: none; /* إزالة أي تأثيرات التركيز */
  -webkit-tap-highlight-color: transparent; /* إزالة تأثير الضغط في الأجهزة التي تعمل باللمس */
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 145px; /* يجب أن يكون نفس ارتفاع .banner-container */
  box-sizing: border-box;
}

.slick-slide img {
  display: block;
  width: 100%;
  height: 100%; /* لضبط ارتفاع الصورة لتناسب ارتفاع الحاوية */
  object-fit: cover; /* لضمان تغطية الصورة بالكامل داخل الحاوية */
}

.slick-dots {
  position: absolute;
  bottom: 10px; /* مسافة من أسفل البنر */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  margin: 0 5px; /* مسافة بين النقاط */
}

.slick-dots li button:before {
  font-size: 10px; /* حجم النقاط */
  color: rgb(9, 167, 173); /* لون النقاط */
}

/* تحديد التأثيرات فقط للبّنر */
.banner-container * {
  outline: none; /* إزالة تأثير التركيز من العناصر داخل البنر */
  border: none; /* إزالة أي حدود من العناصر داخل البنر */
  -webkit-tap-highlight-color: transparent; /* إزالة تأثير الضغط في الأجهزة التي تعمل باللمس */
}

/* أنيميشن اهتزاز السلة */
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px) rotate(-5deg);
  }
  75% {
    transform: translateX(5px) rotate(5deg);
  }
}

.shake-animation {
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  transform-origin: center center;
}

.cart-icon-container {
  position: relative;
  display: inline-block;
}

.cart-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ff4444;
  color: white;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0 0 0 2px rgba(255, 68, 68, 0.3);
  transition: all 0.3s ease;
}

/* تأثير النبض الحاد */
@keyframes pulse-glow {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.2);
  }
  55% {
    transform: scale(0.95);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-animation {
  animation: pulse-glow 0.6s cubic-bezier(0.4, 0, 0.6, 1);
}

.pulse-animation .cart-badge {
  box-shadow: 0 0 0 3px rgba(255, 68, 68, 0.5);
}

/* تأثير القفز */
@keyframes bounce-scale {
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  40% {
    transform: translateY(-8px) scale(1.1);
  }
  60% {
    transform: translateY(-4px) scale(1.05);
  }
}

.bounce-animation {
  animation: bounce-scale 0.6s cubic-bezier(0.36, 0, 0.66, 1);
}

@keyframes number-change {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.number-animation {
  animation: number-change 0.8s ease-out;
}

.custom-padding {
  padding: 1rem 1rem 0 1rem;
  border-radius: 0.5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 4rem;
}

.dark .custom-padding {
  background-color: rgb(55 65 81);
}

.custom-content-padding {
  padding: 1rem 1rem 0 1rem;
  border-radius: 0.5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 4rem;
}

.dark .custom-content-padding {
  background-color: rgb(55 65 81);
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animateItems {
  animation: slideUp 0.5s ease-out;
}

/* إضافة تنسيق جديد للانتقال السريع */
.quick-transition {
  transition-duration: 500ms !important;
}


/* تأثيرات نافذة التثبيت */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(-50%, -40%) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -40%) scale(0.95);
  }
}

.install-backdrop {
  animation: fadeIn 0.2s ease-out forwards;
}

.install-backdrop.closing {
  animation: fadeOut 0.2s ease-out forwards;
}

.install-popup {
  animation: slideIn 0.3s ease-out forwards;
}

.install-popup.closing {
  animation: slideOut 0.2s ease-out forwards;
}

/* تنسيق قائمة الخطوات */
.steps-list {
  counter-reset: step;
  list-style: none;
  padding-right: 1rem;
}

.steps-list li {
  counter-increment: step;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
  gap: 0.5rem;
}

.steps-list li::before {
  content: counter(step)"-";
  color: #6B7280;
}

.steps-list .step-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
